.awssld {
  --caption-background-color: rgba(0, 0, 0, 0.15); }
  .awssld__content [data-type='caption'] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .awssld__content [data-type='caption'] p {
      background-color: var(--caption-background-color);
      padding: 10px 20px;
      color: #fff;
      font-weight: bold;
      -webkit-transition: opacity 300ms ease-out, -webkit-transform 0.425s cubic-bezier(0.05, 0.65, 0.1, 0.95);
      transition: opacity 300ms ease-out, -webkit-transform 0.425s cubic-bezier(0.05, 0.65, 0.1, 0.95);
      transition: transform 0.425s cubic-bezier(0.05, 0.65, 0.1, 0.95), opacity 300ms ease-out;
      transition: transform 0.425s cubic-bezier(0.05, 0.65, 0.1, 0.95), opacity 300ms ease-out, -webkit-transform 0.425s cubic-bezier(0.05, 0.65, 0.1, 0.95);
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      opacity: 1; }
      @media all and (max-width: 600px) {
        .awssld__content [data-type='caption'] p {
          font-size: 16px;
          line-height: 18px; } }
      @media all and (max-width: 400px) {
        .awssld__content [data-type='caption'] p {
          font-size: 12px;
          line-height: 14px; } }
  .awssld__content--exit [data-type='caption'] p {
    -webkit-transition: opacity 275ms ease-out 175ms, -webkit-transform 225ms cubic-bezier(0.85, 0, 0.85, 0.7) 75ms;
    transition: opacity 275ms ease-out 175ms, -webkit-transform 225ms cubic-bezier(0.85, 0, 0.85, 0.7) 75ms;
    transition: transform 225ms cubic-bezier(0.85, 0, 0.85, 0.7) 75ms, opacity 275ms ease-out 175ms;
    transition: transform 225ms cubic-bezier(0.85, 0, 0.85, 0.7) 75ms, opacity 275ms ease-out 175ms, -webkit-transform 225ms cubic-bezier(0.85, 0, 0.85, 0.7) 75ms; }
  .awssld__content--moveLeft [data-type='caption'] p {
    -webkit-transform: translate3d(-50px, 0, 0.01px);
            transform: translate3d(-50px, 0, 0.01px);
    opacity: 0; }
  .awssld__content--moveRight [data-type='caption'] p {
    -webkit-transform: translate3d(50px, 0, 0.01px);
            transform: translate3d(50px, 0, 0.01px);
    opacity: 0; }

